<template>
	<b-modal id="update-asset-details" size="lg" :title="dialogTitle" ref="modal" ok-title="Update" @ok="handleOk"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-tabs>
					<b-tab title="Primary Information" active>
						<b-row>
							<b-col lg="6" md="6" sm="12">
								<b-form-group label="Name" label-for="name"
									description="Numbers, letters, and some special characters: :, -, (, ), \\, /, and , are allowed">
									<b-form-input name="Name" type="text" v-model="selAsset.details.name" v-validate="{
										required: true,
										regex: /^([A-Za-z0-9\s\-:()\\/,]{1,50})$/,
									}" maxlength="50" 
									:readonly="accountingAccess"/>
									<span v-show="errors.has('Name')" class="help-block">
										{{ errors.first('Name') }}
									</span>
								</b-form-group>

								<b-form-group label="Serial No" label-for="serialNo"
									description="Numbers, letters, and some special characters: :, -, (, ), \\, /, and , are allowed">
									<b-form-input name="Serial No" type="text" class="numFont"
										v-model="selAsset.details.serialNo" v-validate="{
											required: false,
											regex: /^([A-Za-z0-9\s\-:()\\/,]{1,50})$/,
										}" maxlength="50" 
										:readonly="accountingAccess"/>
									<span v-show="errors.has('Serial No')" class="help-block">
										{{ errors.first('Serial No') }}
									</span>
								</b-form-group>

								<b-form-group label="Net Book Value (PHP)" label-for="netBookValue">                                    
                                    <b-form-input name="NetBook Value" v-model="selAsset.details.netBookValue" v-validate="'required|positive_decimal'" type="number"
                                        :readonly="!accountingAccess && !managerAccess && !supervisorAccess && !superAdminAccess"
                                        />
                                    <span v-show="errors.has('NetBook Value')" class="help-block">
                                        Please enter a positive number
                                    </span>
                                </b-form-group>

								<b-form-group v-if="assetTagging === 'Custom'" label="Category" label-for="Category">
									<v-select name="Category" class="style-chooser" label="text" :options="categoryOptions"
										:reduce="(category) => category.value" v-model="selAsset.details.category" :disabled="accountingAccess">
										<template v-slot:no-options="{ search, searching }">
											<template v-if="searching">
												No results found for
												<em>
													<strong>{{ search }}</strong>
												</em>
											</template>
											<em :style="{ opacity: 0.5 }" v-else>
												Start typing to search for a category
											</em>
										</template>
									</v-select>
									<span v-show="errors.has('Category')" class="help-block">{{
										errors.first('Category')
										}}</span>
								</b-form-group>

								<b-form-group label="Description" label-for="description" description>
									<b-form-textarea name="Description" type="text" v-model="selAsset.details.description" maxlength="200" :rows="3"
										placeholder="Description" v-validate="{
											required: true,
											regex: remarksRegex
										}" 
										:readonly="accountingAccess"/>
									<span v-show="errors.has('Description')" class="help-block">
										{{ errors.first('Description') }}
									</span>
								</b-form-group>
							</b-col>

							<b-col lg="6" md="6" sm="12">
								<b-form-group label="Asset Photo" label-for="asset-photo"
									description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
									<b-form-file id="asset-photo" placeholder="Choose image" ref="asset-photo-file-input"
										accept="image/png, image/jpeg, image/jpg" @change="onSelectAssetPhoto($event)" :disabled="accountingAccess">
										<template slot="file-name" slot-scope="{ names }">
											<b-badge variant="dark">{{ names[0] }}</b-badge>
											<b-badge v-if="names.length > 1" variant="dark" class="ml-1">+ {{
												names.length - 1 }} More files</b-badge>
										</template>
									</b-form-file>
									<span v-if="isValidAssetPhoto" class="help-block">
										The asset is damaged, Please provide the required asset photo
									</span>
								</b-form-group>

								<!-- Image Preview -->
								<div v-if="isMediaNonNull(selAssetPhoto)">
									<b-card overlay :img-src="selAssetPhoto.url" img-alt="Card Image"
										text-variant="white" class="mb-0">
										<b-card-text class="text-right">
											<b-button variant="danger" size="sm" @click="onRemoveAssetPhoto()">
												<em class="fa fa-trash"></em>
											</b-button>
										</b-card-text>
									</b-card>
									<b-progress v-if="selAssetPhoto.status === 'uploading'"
										:value="selAssetPhoto.percentage" :max="100" variant="success" height="8px"
										animated />
								</div>
							</b-col>
						</b-row>
					</b-tab>

					<b-tab title="Inventory Details">
						<b-row>
							<b-col lg="6" md="6" sm="12">
								<b-form-group label="Condition">
									<b-form-select name="Condition" v-model="selAsset.details.condition"
										v-validate="'required'" :options="conditionOptions" class="mr-2" :disabled="accountingAccess"/>
									<span v-show="errors.has('Condition')" class="help-block">
										{{ errors.first('Condition') }}
									</span>
								</b-form-group>
							</b-col>
							<b-col lg="6" md="6" sm="12">
								<b-form-group label="Unit Cost (PHP)" label-for="Unit Cost" :description="unitCostCaption">
									<b-form-input name="Unit Cost" type="text" class="numFont" step="0.01"
										v-model="selAsset.details.unitCost" v-validate="'required|decimal'"
										maxlength="50" 
										:readonly="accountingAccess"/>
									<span v-show="errors.has('Unit Cost')" class="help-block">
										{{ errors.first('Unit Cost') }}
									</span>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row>
							<b-col lg="6" md="6" sm="12">
								<b-form-group label="Purchase Date">
									<b-form-datepicker v-model="purchaseDate" locale="en" reset-button
										label-reset-button="Clear" :date-format-options="{
											year: 'numeric',
											month: 'short',
											day: '2-digit',
											weekday: 'short',
										}" :date-disabled-fn="dateFromDisabled" 
										:disabled="accountingAccess"/>
									<span v-show="errors.has('Purchase Date')" class="help-block">
										{{ errors.first('Purchase Date') }}
									</span>
								</b-form-group>
							</b-col>

							<b-col lg="6" md="6" sm="12">
								<b-form-group label="Expiration Date"
									description="Default value: 5 years from purchase date">
									<b-form-datepicker v-model="expirationDate" locale="en" reset-button
										label-reset-button="Clear" :date-format-options="{
											year: 'numeric',
											month: 'short',
											day: '2-digit',
											weekday: 'short',
										}" 
										:disabled="accountingAccess"/>
									<span v-show="errors.has('Expiration Date')" class="help-block">
										{{ errors.first('Expiration Date') }}
									</span>
								</b-form-group>
							</b-col>
						</b-row>
					</b-tab>
				</b-tabs>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { FileUtil } from '@/utils/fileUtil';
import { Validator } from 'vee-validate';

// API
import assetApi from '@/api/assetApi';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import { storage } from '@/config/firebase';
import _ from 'lodash';


export default {
	name: 'update-asset-details',
	components: {
		Loading,
	},
	props: {
		isSuperAdmin: {
			type: Boolean,
			required: true,
		},
		allAssetTypesObj: {
			type: Object,
			required: true,
		},
		allCompanyOptions: {
			type: Array,
			required: false,
		},
		allStorageLocationOptions: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			selAsset: {
				details: {
					name: '',
					serialNo: '',
					description: '',
					condition: '',
					uom: '',
					unitCost: 0,
					purchaseDate: 0,
					expirationDate: 0,
                    netBookValue: 0,
				},
			},
			purchaseDate: null,
			expirationDate: null,
			selAssetPhoto: {},
			isValidAssetPhoto: false,

			conditionOptions: [],
			categoryOptions: [],

			currUserId: this.$store.getters.loggedUser.id,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,

			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		purchaseDate(newVal) {
			if ((this.expirationDate === null || this.expirationDate === 0) && (newVal !== null && newVal !== 0)) {
				let timeStamp = moment(newVal).add(5, 'years').valueOf();
				this.expirationDate = new Date(timeStamp);
				this.updateAssetDetailsFromUI();
			}
		}
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		dialogTitle: function () {
			return 'Update Asset Details: ' + this.selAsset.details.assetCode;
		},

		unitCostCaption() {
			let uom = this.selAsset.details.uom ? this.selAsset.details.uom : '-';
			return 'This is the unit cost per ' + uom;
		},
		remarksRegex() {
			return config.remarksRegex;
		},
		assetTagging() {
			let assetTypeObj = this.allAssetTypesObj[this.selAsset.assetTypeId];
			if (!_.isEmpty(assetTypeObj)) {
				return assetTypeObj.assetTagging;
			}
			return "";
		},
		accountingAccess() {
            return this.loggedUser.type === config.accountingRole;
        },
        managerAccess() {
            return this.loggedUser.type === config.managerRole;
        },
        supervisorAccess() {
            return this.loggedUser.type === config.supervisorRole;
        },
        superAdminAccess() {
            return this.loggedUser.type === config.superAdminRole;
        },
	},
	mounted() {
		EventBus.$on('onUpdateSelAsset', (asset) => {
			this.selAsset = asset;
			this.selAsset.details.netBookValue = this.selAsset.details.netBookValue ? this.selAsset.details.netBookValue : 0;
			this.initAssetDetails();
		});
	},
	methods: {
		initAssetDetails() {
			let details = this.selAsset.details;

			this.purchaseDate = null;
			this.expirationDate = null;

			let assetTypeId = this.selAsset.assetTypeId;
			let assetTypeObj = this.allAssetTypesObj[assetTypeId];

			// init condition options
			this.conditionOptions = this.getAssetConditions(assetTypeObj);
			// init categories options
			this.categoryOptions = this.getAssetCategories(assetTypeObj);

			if (!_.isEmpty(details)) {
				// init name
				if (!details.name) {
					this.selAsset.details.name = assetTypeObj.name;
				}

				// init asset photo
				this.selAssetPhoto = {
					url: details.imgUrl,
					_isNew: false
				}

				if (details.purchaseDate) {
					this.purchaseDate = new Date(details.purchaseDate);
				}
				if (details.expirationDate) {
					this.expirationDate = new Date(details.expirationDate);
				}
			}

		},

		getAssetConditions(assetTypeObj) {
			let conditions = [];

			if (assetTypeObj && assetTypeObj.conditions && !_.isEmpty(assetTypeObj.conditions)) {
				conditions = DropDownItemsUtil.retrieveAssetConditions(assetTypeObj.conditions);
			} else {
				conditions = config.assetConditionStatus;
			}

			return conditions;
		},
		getAssetCategories(assetTypeObj) {
			let categories = [];

			if (assetTypeObj && assetTypeObj.categories && !_.isEmpty(assetTypeObj.categories)) {
				categories = DropDownItemsUtil.retrieveAssetCategories(assetTypeObj.categories);
			}

			return categories;
		},

		dateFromDisabled(_ymd, date) {
			return date > new Date();
		},
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();
			this.updateAssetDetailsFromUI();

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				return;
			}

			let purchaseDate = this.selAsset.details.purchaseDate;
			let expirationDate = this.selAsset.details.expirationDate;
			if (purchaseDate > 0 || expirationDate > 0) {
				if (purchaseDate > 0 && !expirationDate) {
					this.$toaster.warning('Expiration Date is required when Purchase Date has value.');
					return;
				} else if (!purchaseDate && expirationDate > 0) {
					this.$toaster.warning('Purchase Date is required when Expiration Date has value.');
					return;
				} else if (expirationDate > 0 && expirationDate <= purchaseDate) {
					this.$toaster.warning('Invalid Date Range. Expiration Date must be greater than Purchase Date.');
					return;
				}
			}

			if (this.selAsset.details.condition == 'Damaged' && (!this.selAssetPhoto || !this.selAssetPhoto.url)) {
				this.$toaster.warning('The asset is damaged, Please provide the required asset photo');
				this.isValidAssetPhoto = true;
				return;
			}

			this.isValidAssetPhoto = false;

			this.handleSubmit();
		},
		updateAssetDetailsFromUI() {
            this.selAsset.details.unitCost = parseFloat(this.selAsset.details.unitCost);
            this.selAsset.details.netBookValue = parseFloat(this.selAsset.details.netBookValue);

			if (this.purchaseDate) {
				this.selAsset.details.purchaseDate = new Date(this.purchaseDate).getTime();
			} else {
				this.selAsset.details.purchaseDate = 0;
			}

			if (this.expirationDate) {
				this.selAsset.details.expirationDate = new Date(this.expirationDate).getTime();
			} else {
				this.selAsset.details.expirationDate = 0;
			}

			if (_.isEmpty(this.selAssetPhoto)) {
				this.selAsset.details.imgUrl = "";
			}
		},
		getParam() {
			let assets = {};

			let assetCode = this.selAsset.details.assetCode;
			assets[assetCode] = this.selAsset;

			return {
				currUserId: this.currUserId,
				currTimeStamp: DateUtil.getCurrentTimestamp(),
				assets: assets,
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let param = this.getParam();
				let { data } = await assetApi.updateAssets(param);

				if (data.isSuccess) {
					let assetCode = this.selAsset.details.assetCode;

					// upload company logo
					let asset = data.assets[assetCode];
					let newAsset = await this.uploadAssetPhoto(asset);
					data.assets = { [newAsset.assetCode]: newAsset };

					this.$toaster.success(assetCode + ' details were updated.');
					EventBus.$emit('onCloseSaveAssets', data.assets);
					this.$refs.modal.hide();
				} else {
					this.$toaster.warning(data.message);
				}

			} catch (_error) {
				this.$toaster.error('Error updating asset(s). Please try again.');
			}

			// hide loading indicator
			this.isLoading = false;
		},

		async uploadAssetPhoto(asset) {
			let image = this.selAssetPhoto;

			if (image._isNew) {
				try {
					const result = await this.firebaseUploadImage(image, asset);

					if (result.name && result.url) {
						asset.details.imgUrl = result.url;
						let newAssets = { [asset.assetCode]: asset };
						let { data } = await assetApi.updateAssets({
							currUserId: this.currUserId,
							currTimeStamp: DateUtil.getCurrentTimestamp(),
							assets: newAssets,
						});

						if (data.isSuccess) {
							this.onUploadInProgress('success');
						}
					}
				} catch (_error) {
					this.$toaster.warning('Failed to upload the asset photo.');
				}
			}
			return asset;
		},

		async firebaseUploadImage(image, asset) {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			let name = `AD_${asset.assetCode}_${currTimeStamp}.png`;
			this.onUploadInProgress('uploading', 0);

			return new Promise((resolve, reject) => {
				let storageRef = storage.ref(`${image.fbStoragePath}/${name}`);
				let task = storageRef.put(image.file);

				task.on(
					'state_changed',
					(snapshot) => {
						let percentage =
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						this.onUploadInProgress('uploading', percentage);
					},
					(error) => {
						reject(error);
						this.onResetUploadState();
					},
					() => {
						task.snapshot.ref.getDownloadURL().then((downloadURL) => {
							resolve({
								name: name,
								url: downloadURL,
							});
						});
					}
				);
			});
		},

		isMediaNonNull(media) {
			return media && media.url
		},

		onSelectAssetPhoto(evt) {
			const vm = this;
			const file = evt.target.files[0];

			if (!FileUtil.isValidImgFileType(file)) {
				this.$toaster.error(
					'Invalid File Type: Please import a valid asset photo in PNG or JPEG format.'
				);
				this.selAssetPhoto = {};
				return;
			}

			const url = URL.createObjectURL(file);
			let dimensions = { w: 0, h: 0 };

			const image = new Image();
			image.onload = function () {
				dimensions.w = image.width;
				dimensions.h = image.height;

				if (dimensions.w > 1000 || dimensions.h > 1000) {
					vm.$toaster.warning(
						"Asset photo width and height shouldn't be greater than 1000 pixels"
					);
				} else {
					vm.selAssetPhoto = {
						url: url,
						file: file,
						fbStoragePath: 'images/assetDetails',
						_isNew: true,
					};
				}
			};
			image.src = url;
		},

		onRemoveAssetPhoto() {
			this.selAssetPhoto = {};
			this.$refs['asset-photo-file-input'].reset();
		},

		onUploadInProgress(status, percentage = 0) {
			this.selAssetPhoto.percentage = percentage;
			this.selAssetPhoto.status = status;
		},

		onResetUploadState() {
			this.selAssetPhoto.percentage = 0;
			this.selAssetPhoto.status = '';
		},

	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelAsset');
	},
};
</script>
